

































































































































































































































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { formatDateTime, millisToTimeString } from '@/utils/tools';
import ReactionBarGraph from '@/components/Presenter/ReactionBarGraph.vue';
import PollResults from '@/components/Presenter/PollResults.vue';
import PollResultsWithTextField from '@/components/Presenter/PollResultsWithTextField.vue';
import ThemeModel from '@/dtos/theme';

export default Vue.extend({
  components: {
    ReactionBarGraph,
    PollResults,
    PollResultsWithTextField,
  },
  data() {
    return {
      now: new Date(),
      pollTypes: ['Singlechoice', 'Multiplechoice '],
      renderAnimation: false,
      animationName: 'demo',
      animationDuration: 800,
    };
  },
  async mounted() {
    await this.$store.dispatch(
      'presenter/fetchTVSession',
      this.$route.params.sessionId
    );
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
      sessionViewers: 'viewer/getSessionViewers',
      columnQuestions: 'questions/getColumnQuestions',
      sessionPolls: 'presenter/getSessionPolls',
      wordclouds: 'presenter/getSessionWordclouds',
      sessionTheme: 'viewer/getSessionTheme',
    }),

    theme(): ThemeModel {
      return this.sessionTheme as ThemeModel;
    },
    questions(): any[] {
      const currentColumnId = this.currentSession.tv.includes('questions')
        ? this.currentSession.tv.split('-')[2]
        : 'default';
      // return this.columnQuestions(currentColumnId).filter(
      //   (item: any) => item.isVisible === true
      // );
      return this.columnQuestions(currentColumnId);
    },
    polls(): any[] {
      return this.sessionPolls.filter((item: any) => item.isActive === true);
    },
    wordcloud(): any {
      if (this.wordclouds && this.currentSession) {
        if ('activeWordcloud' in this.currentSession) {
          const [wordcloud] = this.wordclouds.filter(
            (item: any) => item.id === this.currentSession.activeWordcloud
          );
          return wordcloud;
        }
      }
      return {};
    },
    words(): any[] {
      if (this.wordcloud) {
        const list = [];
        for (const key in this.wordcloud) {
          if (key !== 'id') {
            list.push([key, this.wordcloud[key]]);
          }
        }
        return list;
      }
      return [];
    },
    activeWords() {
      let activeWordList: any[] = this.words;

      if (this.currentSession && this.currentSession.deactivatedWordsList) {
        activeWordList = activeWordList.filter(
          (n) => !this.currentSession.deactivatedWordsList.includes(n[0])
        );
      }

      return activeWordList;
    },
    elapsedTime(): string {
      if (
        this.currentSession &&
        this.currentSession.startAt &&
        this.currentSession.endAt
      ) {
        if (
          this.now > this.currentSession.startAt.toDate() &&
          this.now < this.currentSession.endAt.toDate()
        ) {
          const elapsedTime =
            this.now.getTime() - this.currentSession.startAt.toDate().getTime();
          return millisToTimeString(elapsedTime);
        } else if (this.now > this.currentSession.endAt.toDate()) {
          return this.$t('views.presenter.sessionEnded') as string;
        }
        return (
          (this.$t('views.presenter.sessionStartsAt') as string) +
          `${formatDateTime(this.currentSession.startAt.toDate())}`
        );
      }
      return '-';
    },
    remainingTime(): string {
      if (
        this.currentSession &&
        this.currentSession.startAt &&
        this.currentSession.endAt
      ) {
        if (
          this.now > this.currentSession.startAt.toDate() &&
          this.now < this.currentSession.endAt.toDate()
        ) {
          const remainingTime =
            this.currentSession.endAt.toDate().getTime() - this.now.getTime();
          return millisToTimeString(remainingTime);
        }
      }
      return '';
    },
    computedReactionsCount(): number {
      let countReactions = 0;
      if (this.currentSession) {
        if ('reactions' in this.currentSession) {
          for (const count of Object.values(this.currentSession.reactions)) {
            countReactions += count as number;
          }
        }
      }
      return countReactions;
    },
    computedActiveUsers(): number {
      if (this.sessionViewers) {
        return this.sessionViewers.length;
      }
      return 0;
    },
    computedReactions(): any {
      if (this.currentSession && this.currentSession.reactions) {
        const { reactions } = this.currentSession;
        if (this.theme.limitReactions && this.theme.reactions) {
          const sessionReactions = [];
          for (const reactionName of this.theme.reactions) {
            switch (reactionName) {
              case 'thumbs-up':
                sessionReactions.push({
                  name: 'thumbs-up',
                  imageName: 'reaction-thumbs-up.png',
                  color: this.theme.primaryColor,
                  value: reactions.thumbsUpCount,
                  fillLevel:
                    (reactions.thumbsUpCount / this.computedReactionsCount) *
                    100,
                  label: this.$t('views.thumbsUp'),
                });
                break;
              case 'thumbs-down':
                sessionReactions.push({
                  name: 'thumbs-down',
                  imageName: 'reaction-thumbs-down.png',
                  color: this.theme.primaryColor,
                  value: reactions.thumbsDownCount,
                  fillLevel:
                    (reactions.thumbsDownCount / this.computedReactionsCount) *
                    100,
                  label: this.$t('views.thumbsDown'),
                });
                break;
              case 'love':
                sessionReactions.push({
                  name: 'love',
                  imageName: 'reaction-love.png',
                  color: this.theme.primaryColor,
                  value: reactions.heartCount,
                  fillLevel:
                    (reactions.heartCount / this.computedReactionsCount) * 100,
                  label: this.$t('views.loveIt'),
                });
                break;
              case 'funny':
                sessionReactions.push({
                  name: 'funny',
                  imageName: 'reaction-funny.png',
                  color: this.theme.primaryColor,
                  value: reactions.funEmojiCount,
                  fillLevel:
                    (reactions.funEmojiCount / this.computedReactionsCount) *
                    100,
                  label: this.$t('views.laughing'),
                });
                break;
              case 'sad':
                sessionReactions.push({
                  name: 'sad',
                  imageName: 'reaction-sad.png',
                  color: this.theme.primaryColor,
                  value: reactions.sadEmojiCount,
                  fillLevel:
                    (reactions.sadEmojiCount / this.computedReactionsCount) *
                    100,
                  label: this.$t('views.crying'),
                });
                break;
              case 'smile':
                sessionReactions.push({
                  name: 'smile',
                  imageName: 'reaction-smile.png',
                  color: this.theme.primaryColor,
                  value: reactions.sadEmojiCount,
                  fillLevel:
                    (reactions.sadEmojiCount / this.computedReactionsCount) *
                    100,
                  label: this.$t('views.smile'),
                });
                break;
              case 'claps':
                sessionReactions.push({
                  name: 'claps',
                  imageName: 'reaction-claps.png',
                  color: this.theme.primaryColor,
                  value: reactions.clapsCount,
                  fillLevel:
                    (reactions.clapsCount / this.computedReactionsCount) * 100,
                  label: this.$t('views.clapping'),
                });
                break;
              case 'rocket':
                sessionReactions.push({
                  name: 'rocket',
                  imageName: 'reaction-rocket.png',
                  color: this.theme.primaryColor,
                  value: reactions.rocketCount,
                  fillLevel:
                    (reactions.rocketCount / this.computedReactionsCount) * 100,
                  label: this.$t('views.rocket'),
                });
                break;
              default:
                break;
            }
          }
          return sessionReactions;
        }
        return [
          {
            name: 'thumbs-up',
            imageName: 'reaction-thumbs-up.png',
            color: this.theme.primaryColor,
            value: reactions.thumbsUpCount,
            fillLevel:
              (reactions.thumbsUpCount / this.computedReactionsCount) * 100,
            label: this.$t('views.thumbsUp'),
          },

          {
            name: 'funny',
            imageName: 'reaction-funny.png',
            color: this.theme.primaryColor,
            value: reactions.funEmojiCount,
            fillLevel:
              (reactions.funEmojiCount / this.computedReactionsCount) * 100,
            label: this.$t('views.laughing'),
          },
          {
            name: 'sad',
            imageName: 'reaction-sad.png',
            color: this.theme.primaryColor,
            value: reactions.sadEmojiCount,
            fillLevel:
              (reactions.sadEmojiCount / this.computedReactionsCount) * 100,
            label: this.$t('views.crying'),
          },
          {
            name: 'claps',
            imageName: 'reaction-claps.png',
            color: this.theme.primaryColor,
            value: reactions.clapsCount,
            fillLevel:
              (reactions.clapsCount / this.computedReactionsCount) * 100,
            label: this.$t('views.clapping'),
          },
          {
            name: 'rocket',
            imageName: 'reaction-rocket.png',
            color: this.theme.primaryColor,
            value: reactions.rocketCount,
            fillLevel:
              (reactions.rocketCount / this.computedReactionsCount) * 100,
            label: this.$t('views.rocket'),
          },
          {
            name: 'love',
            imageName: 'reaction-love.png',
            color: this.theme.primaryColor,
            value: reactions.heartCount,
            fillLevel:
              (reactions.heartCount / this.computedReactionsCount) * 100,
            label: this.$t('views.loveIt'),
          },
        ];
      }
      return [];
    },
    computedTabState(): number {
      if (this.currentSession) {
        return this.currentSession.showGreenscreen ? 1 : 0;
      }
      return 0;
    },
    isGreenscreenEnabled(): boolean {
      return (
        this.sessionTheme.isGreenscreenFeatureEnabled &&
        this.currentSession.showGreenscreen
      );
    },
  },
  methods: {
    selectTab(id: string, value: boolean): void {
      this.$store.dispatch(
        'presenter/toggleGreenscreenVisibilitySetting',
        value
      );
    },
    assignReactionAnimation(name: string) {
      if (this.renderAnimation) {
        setTimeout(() => {
          this.renderAnimation = true;
          this.animationName = name;
        }, this.animationDuration);
      } else {
        this.renderAnimation = true;
        this.animationName = name;
      }
    },
  },

  watch: {
    currentSession(newSession, oldSession) {
      if (newSession && oldSession) {
        if (newSession.reactions && oldSession.reactions) {
          if (
            newSession.reactions.clapsCount > oldSession.reactions.clapsCount
          ) {
            this.assignReactionAnimation('claps');
          } else if (
            newSession.reactions.funEmojiCount >
            oldSession.reactions.funEmojiCount
          ) {
            this.assignReactionAnimation('funny');
          } else if (
            newSession.reactions.heartCount > oldSession.reactions.heartCount
          ) {
            this.assignReactionAnimation('love');
          } else if (
            newSession.reactions.rocketCount > oldSession.reactions.rocketCount
          ) {
            this.assignReactionAnimation('rocket');
          } else if (
            newSession.reactions.sadEmojiCount >
            oldSession.reactions.sadEmojiCount
          ) {
            this.theme.name === 'igbce-kongress'
              ? this.assignReactionAnimation('smile')
              : this.assignReactionAnimation('sad');
          } else if (
            newSession.reactions.thumbsUpCount >
            oldSession.reactions.thumbsUpCount
          ) {
            this.assignReactionAnimation('thumbs-up');
          }
        }
      }
    },
  },
});
