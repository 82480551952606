









































import ThemeModel from '@/dtos/theme';
import PollResults from './PollResults.vue';
import Vue from 'vue';
export default Vue.extend({
  name: 'PollResultsWithTextField',
  components: { PollResults },
  props: {
    choices: {
      type: Array,
      default: () => [],
      required: true,
    },
    answers: {
      type: Object,
      required: true,
    },
    theme: {
      type: Object,
      required: false,
      default: () => ThemeModel.defaultTheme(),
    },
    invertColors: {
      type: Boolean,
      required: false,
      default: false,
    },
    voteResultType: {
      type: Number,
      required: false,
      default: 0,
    },
    hidePollLabels: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: 'Antworten:',
    },
  },
  computed: {
    filteredAnswers() {
      const res = [];
      const tempAnswers = this.answers as { [id: string]: any[] };
      for (const userId in tempAnswers) {
        const filteredEntry = tempAnswers[userId].filter(
          (answer: any) => typeof answer === 'string'
        );
        if (filteredEntry.length > 0) {
          res.push(filteredEntry);
        }
      }
      return res;
    },
  },
});
