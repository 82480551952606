








































import Vue from 'vue';
import ThemeModel from '@/dtos/theme';
export default Vue.extend({
  name: 'PollResults',
  props: {
    choices: {
      type: Array,
      default: () => [],
      required: true,
    },
    answers: {
      type: Object,
      required: true,
    },
    theme: {
      type: Object,
      required: false,
      default: () => ThemeModel.defaultTheme(),
    },
    embedded: {
      type: Boolean,
      required: false,
      default: false,
    },
    invertColors: {
      type: Boolean,
      required: false,
      default: false,
    },
    voteResultType: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  methods: {
    calcAbsForAnswers(
      answers: any[],
      choice: number
    ): { absCount: number; sum: number } {
      let absCount = 0;
      let sum = 0;
      if (answers) {
        Object.values(answers).map((answer: any) => {
          if (answer instanceof Array) {
            sum += answer.length;
            if (answer.includes(choice)) {
              absCount += 1;
            }
          } else {
            sum += 1;
            if (answer === choice) {
              absCount += 1;
            }
          }
        });
      }
      return { absCount, sum };
    },
    calcPercForAnswers(answers: any[], choice: number): number {
      const { absCount, sum } = this.calcAbsForAnswers(answers, choice);
      if (absCount === 0 && sum === 0) {
        return 0;
      }
      return Math.floor((absCount / sum) * 100);
    },
  },
});
